<template>
  <!-- 新人奖励列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="glo_btnList" ref="btnList">
      <div class="glo_btnList_left">
        <el-button class="glo_btnItems glo_bgcMainColor" @click="AddBtns()"
          >新建</el-button
        >

        <!-- <el-dropdown class="topDropDown" @command="topCommandBtns">
          <el-button type="primary">
            更多操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dtdc">导出</el-dropdown-item>
            <el-dropdown-item command="bgsz">表格设置</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <queryBtns
        :MoreConditions="MoreConditions"
        @clerBtns="clerBtns"
        @queryBtn_ok="queryBtn_ok"
        @moreParam="moreParam"
      ></queryBtns>
    </div>
    <!-- 查询条件 -->
    <!-- :formData="queryData" -->
    <queryCondition
      ref="queryForm"
      :More="isHaseMore"
      :formConfig="queryCondditionDatas"
    ></queryCondition>
    <!-- 表格 -->
    <div class="glo_Table">
      <el-table
        :height="tableHeigth"
        header-align="center"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        border
        stripe
        :highlight-current-row="true"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          align="center"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          align="center"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <!-- 某个字段需要单独操作  例如点击可以跳转 -->
          <el-table-column
            v-if="item.field == 'aaaaaaaaaa' && item.isShow"
            :key="index"
            :label="item.name"
            :prop="item.field"
            align="left"
            sortable
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
          >
            <template slot-scope="scope">
              <div class="linkTabel">
                {{ scope.row.aaaaaaaaaa }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            align="center"
            sortable
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-else-if="item && item.isShow"
          ></el-table-column>
        </template>
        <el-table-column fixed="right" label="操作" min-width="180px">
          <template slot-scope="scope">
            <div class="tabRightBtns">
              <el-button class="rbtns" @click.stop="ModifyBtns(scope.row)"
                >修改</el-button
              >
              <!-- 如果按钮超过4个就要用下面这种下拉的样式 -->
              <!-- <el-dropdown
                  class="rbtns"
                  @command="handleCommandBtns($event, scope.row)"
                >
                  <el-button type="primary">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="a">详情</el-dropdown-item>
                    <el-dropdown-item command="b">外发录入</el-dropdown-item>
                    <el-dropdown-item command="c">改运单号</el-dropdown-item>
                    <el-dropdown-item command="d">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <paging
      ref="pags"
      :pageNum="pageStart"
      :total="pageCount"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>
    <!--        自定义表头           -->
    <!-- <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable> -->

    <!-- 导出插件 -->
    <!-- <exportCompoent
      :isShowDialog_dc="isShowDialog_dc"
      :diaWidth_dc="diaWidth_dc"
      :dialogTitle_dc="dialogTitle_dc"
      :dialogForm_dc="dialogForm_dc"
      :tableHeard="EPtableHeard"
      :AllCheck="EPAllCheck"
      :queryCondditionDatas="queryCondditionDatas"
      @checktabEP="checktabEP"
      @checkAllEP="checkAllEP"
      @onAfterEnter="onAfterEnter"
      @handleCloseDia_dc="handleCloseDia_dc"
      @diaConfirmBtn_dc="diaConfirmBtn_dc()"
    >
    </exportCompoent> -->

    <!-- 新建/修改优惠券弹窗 -->
    <myDialog
      :diaWidth="diaWidth2"
      :dialogTitle="dialogTitle2"
      :isShowDialog="isShowDialog2"
      @handleCloseDia="handleCloseDia2"
      @diaCancelBtn="handleCloseDia2"
      @diaConfirmBtn="diaConfirmBtn2('dialogFormRef2')"
    >
      <div class="listItmes">

        <div class="directionS">
          <div class="demonstration">状态:</div>
          <el-select
              class="selectItems"
              v-model="dialogForm2.isEnable"
              placeholder="请选择"
          >
            <el-option
                v-for="item in IsEnableList"
                :key="item.name"
                :label="item.name"
                :value="item.val"
            >
            </el-option>
          </el-select>
        </div>
        <div class="directionS">
          <div class="demonstration">发放节点:</div>
          <el-select
              class="selectItems"
              v-model="dialogForm2.sendNoteType"
              placeholder="请选择"
          >
            <el-option
                v-for="item in isGradient"
                :key="item.name"
                :label="item.name"
                :value="item.val"
            >
            </el-option>
          </el-select>
        </div>

        <!-- 发放条件 -->
        <div class="block">
          <span class="demonstration">发放条件:</span>
          <div class="divA">
            <div class="directionS">
              <div class="leftWidth">满足金额:</div>
              <input
                  class="inputA"
                  placeholder="请输入"
                  type="text"
                  v-model="dialogForm2.conditionAmount"
              />
            </div>

            <div class="directionS">
              <div class="leftWidth">金额计算方式:</div>
              <el-select
                  class="selectItems"
                  v-model="dialogForm2.conditionAmountSumMethod"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in conditionAmountSumMethodList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <div class="directionS">
              <div class="leftWidth">新人天数范围:</div>
              <input
                  class="inputA"
                  placeholder="请输入"
                  type="text"
                  v-model="dialogForm2.newcomerDay"
              />
            </div>

            <div class="directionS">
              <div class="leftWidth">活动时间:</div>
              <el-date-picker
                  class="input"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="dialogForm2.startTime"
                  type="datetime"
                  default-time="00:00:00"
                  placeholder="选择日期"
              ></el-date-picker>
                <div>至</div>
                <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="dialogForm2.endTime"
                    type="datetime"
                    default-time="23:59:59"
                    placeholder="选择日期"
                ></el-date-picker>

            </div>
          </div>
        </div>
        <!-- 奖励内容 -->
        <div class="block">
          <span class="demonstration">奖励内容:</span>
          <div class="divA">
            <div class="directionS">
              <el-checkbox label="" v-model="dialogForm2.isSendCoupon"></el-checkbox>
              <div class="leftWidth">优惠券:</div>
              <el-select
                  v-if="dialogForm2.isSendCoupon"
                  class="selectItems"
                  v-model="dialogForm2.couponId"
                  placeholder="请选择"
                  :remote-method="(val) => getCouponList(true,val)"
                  @visible-change = "getCouponList($event, val)"
              >
                <el-option
                    v-for="item in couponList"
                    :key="item.couponId"
                    :label="item.couponName"
                    :value="item.couponId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="directionS">
              <el-checkbox label="" v-model="dialogForm2.isSendRecharge"></el-checkbox>
              <div class="leftWidth">充值金:</div>
              <input
                  v-if="dialogForm2.isSendRecharge"
                  class="inputA"
                  placeholder="请输入金额"
                  type="text"
                  v-model="dialogForm2.rechargeAmount"
              />
            </div>
          </div>
        </div>
      </div>
    </myDialog>


  </div>
</template>
  <script>
import tableList from "@/pages/DynamicList/tableList.js";
//   import sorttable from "@/components/sortTemplate.vue"; //自定义排序表头
import paging from "@/components/pagings.vue"; //分页组件
import queryBtns from "@/components/queryBtnsDT.vue";
import queryCondition from "@/components/queryCondition.vue";
import myDialog from "@/components/DialogDT.vue";

//   import exportjs from "@/assets/js/Mixins/exportDT.js"; //导出的公共js

export default {
  mixins: [tableList],
  inject: ["reload"],
  components: {
    //   sorttable,
    paging,
    queryBtns,
    queryCondition,
    myDialog,
  },
  data() {
    return {
      color1: "#f9087c",
      color2: "#f9087c",
      color3: "#f8ebf1",
      gradientDirection: "to bottom", //渐变方向

      colorsType: "", //
      isGradient: [
        {
          name: "充值成功",
          val: "recharge:send_note_type",
        },
        {
          name: "订单支付成功",
          val: "pay_order:send_note_type",
        },
        {
          name: "邀请加入即发放",
          val: "into_customer:send_note_type",
        },
        {
          name: "充值成功或者订单支付成功",
          val: "recharge_pay_order:send_note_type",
        },
      ],
      YesOrNoList: [
        {
          name: "是",
          val: 1,
        },
        {
          name: "否",
          val: 0,
        },
      ],
      IsEnableList: [
        {
          name: "进行中",
          val: 1,
        },
        {
          name: "过期",
          val: 0,
        },
      ],
      conditionAmountSumMethodList: [
        {
          name: "单笔",
          val: "one:condition_amount_sum_method",
        },
        {
          name: "累计",
          val: "all:condition_amount_sum_method",
        },
      ],
      couponList: [],

      isShowDialog2: false,
      dialogTitle2: "新建新人奖励",
      diaWidth2: "910px",
      dialogForm2: {
        awardId: "", //
        couponId: "", //
        newcomerDay: "", //
        sendNoteType: "", //
        conditionAmountSumMethod: "", //
        rechargeAmount: "", //
        conditionAmount: "", //
        startTime: "", //
        endTime: "", //
        isSendRecharge: false, //
        isSendCoupon: false, //
        isEnable: 1, //
      },
      rulesForm2: {
        sendNoteType: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        conditionAmountSumMethod: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        isEnable: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        conditionAmount: [
          { required: true, message: "请输入金额", trigger: ["blur", "change"] },
        ],
        newcomerDay: [
          { required: true, message: "请输入天数", trigger: ["blur", "change"] },
        ],
        startTime: [
          { required: true, message: "请选择开始日期", trigger: ["blur", "change"] },
        ],
        endTime: [
          { required: true, message: "请选择结束日期", trigger: ["blur", "change"] },
        ],
      },
      isShowDialog3: false,
      dialogForm3: {
        userIds: [],
        userId: "",
        couponId: "",
      },
      //=======================导出相关 开始 =======================
      dialogForm_dc: {
        pageStart: 1,
        pageTotal: "",
        fileName: "新人邀请奖励列表", //导出时默认文件名
        apiName: "/v1/common/query/runPageReport", //导出的数据列表接口名(动态列表都是这个)
        EpType: "bdd", //dd==等待下载完成,bdd==不等待完成
        apiType: "POST", //导出当前列表的请求方式
        isJson: 1, // 导出当前列表的请求头是否json
      },
      //=======================导出相关 结束 =======================
      pageGroupName: "InvitationAwardList", //  页面标识
      reportFiledsNumber: "invitation_award_newcomer", // 动态 条件、表头
      // command_list admin_waybill_posting_list

      currentItemArr: [],
      dialogType: "",
      showTimers:false,
    };
  },
  mounted() {},
  watch: {
    colorsType: function (a, b) {
      console.log(a);
      if (a == "chun_se:colors_type") {
        this.color3 = "";
      }
    },
  },
  methods: {
    AddBtns() {
      this.dialogType = "add";
      this.isShowDialog2 = true;
      this.dialogTitle2 = "新建新人奖励";
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.currentItemArr = e || [];
    },


    async ModifyBtns(row) {
      console.log(row);
      this.dialogForm2 = {
        awardId: row.awardId || null,
        conditionAmount: row.conditionAmount || 0,
        rechargeAmount: row.rechargeAmount || 0,
        conditionAmountSumMethod: row.conditionAmountSumMethod || null,
        couponId: row.couponId || null,
        newcomerDay: row.newcomerDay || null,
        sendNoteType: row.sendNoteType || null,
        isSendCoupon: row.isSendCoupon === 1,
        isSendRecharge: row.isSendRecharge===1,
        isEnable: row.isEnable ? 1 : 0,
        startTime: row.startTime || null,
        endTime: row.endTime || null,
      };
      this.dialogType = "edit";
      this.isShowDialog2 = true;
      this.dialogTitle2 = "编辑新人奖励";
    },

    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia2() {
      this.isShowDialog2 = false;

      this.dialogForm2 = {
        awardId: "", //
        couponId: "", //
        newcomerDay: "", //
        sendNoteType: "", //
        conditionAmountSumMethod: "", //
        rechargeAmount: "", //
        conditionAmount: "", //
        startTime: "", //
        endTime: "", //
        isSendRecharge: false, //
        isSendCoupon: false, //
        isEnable: 1, //
      };
    },

    handleCloseDia3() {
      this.isShowDialog3 = false;
      this.dialogForm3 = {
        userIds: [],
        userId: "",
        couponId: [],
      };
    },



    //  弹窗确认保存事件
    diaConfirmBtn2(formName) {
      let datas = JSON.parse(JSON.stringify(this.dialogForm2));
      let param = {
        awardId: this.dialogForm2.awardId || null,
        conditionAmount: this.dialogForm2.conditionAmount || 0,
        rechargeAmount: this.dialogForm2.rechargeAmount || 0,
        conditionAmountSumMethod: this.dialogForm2.conditionAmountSumMethod || null,
        couponId: this.dialogForm2.couponId || null,
        newcomerDay: this.dialogForm2.newcomerDay || null,
        sendNoteType: this.dialogForm2.sendNoteType || null,
        isSendCoupon: this.dialogForm2.isSendCoupon ? 1 : 0,
        isSendRecharge: this.dialogForm2.isSendRecharge ? 1 : 0,
        isEnable: this.dialogForm2.isEnable ? 1 : 0,
        startTime: this.dialogForm2.startTime || null,
        endTime: this.dialogForm2.endTime || null,
      };
      param.sign = this.Tools.getSign(param);
      console.log(this.dialogType)

      if (this.dialogType === "edit") {
        // 修改
        this.$API
            .updateInvitationAward(param)
            .then((res) => {
              console.log("res", res);

              let that = this;
              setTimeout(() => {
                that.handleCloseDia2();
                this.$message.success(res.data.message || "");
                that.queryBtn_ok();
              }, 800);

            });

      } else {
        // 新增
        param.sign = this.Tools.getSign(param);
        this.$API
            .addInvitationAward(param)
            .then((res) => {
              console.log("res", res);

              let that = this;
              setTimeout(() => {
                that.handleCloseDia2();
                this.$message.success(res.data.message || "");
                that.queryBtn_ok();
              }, 800);

            });

      }

    },

    changeGetMethod(e) {
      console.log(e);
    },

    couponChange(e) {
      console.log("e", e);
      this.couponId = e;
    },

    getCouponList(e,name) {
      if(!e)
        return;
      let params = {
        conditionReqs: [
          {
            conditonKey: "couponName",
            conditonVal: name || "",
          },
        ],
        number: "down_list_coupon_list",
      };

      this.$API.runPageReportDetails(params).then((res) => {
        if (res.data.status === "success") {
          let datas = res.data.result.datas || [];
          this.couponList = datas;
        }
      });
    },
  },
};
</script>
  <style scoped lang="scss">
::v-deep .el-date-editor.el-input {
  width: auto !important;
}
$borderColor: #666;
.classA {
  display: flex;
}

.glo_dialogForm .diaForm .dialogFormItems {
  height: 30px !important;
  line-height: 30px !important;
  overflow: hidden;
}

.couponBox {
  width: 100%;
  margin-bottom: 20px;
  height: 34px;

  .couponitmeInput {
    width: 100px;
    border: 1px solid #cccccc;
    margin: 0 6px;

    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }

  .couponItemA {
    display: flex;
    align-items: center;
  }
}

.displays {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rows {
  display: flex;
  width: 100%;
}

.multipleSelect {
  ::v-deep .el-select {
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
}


.block {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.demonstration {
  margin-right: 10px;
  width: 120px;
  font-size: 16px;
}

.btnColor {
  background-color: #f0a261;
  border: none;
  color: #fff;
  padding: 10px !important;
}

.testBOx {
  width: 200px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  //   background-image: linear-gradient(to bottom, red, yellow);
}

.directionS {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .selectItems {
    width: 225px;
    // margin: 10px;
  }

  .leftWidth {
    width: 100px;
    font-size: 14px;
  }
}

.divA {
  border: 1px dashed $borderColor;
  width: 500px;
  background-color: #f0f8fa;
  padding: 20px;
}

.inputA {
  height: 30px;
  line-height: 30px;
  width: 217px;
}
</style>
  